import {
    CheckUniqueBankAccountRequest,
    CheckUniqueBankAccountResponse,
    IRequest,
    ProfileType,
    ResponseItem,
    stringToNumber,
} from '@dltru/dfa-models'
import {RcFile} from 'antd/lib/upload'
import moment, {Moment} from 'moment'
import {FieldData} from 'rc-field-form/lib/interface'

import {MAX_LOGIN_LENGTH, VIEW_DATE_FORMAT} from '../constants'
import {RUS_DATETIME_FORMAT} from './date'

export const NOT_EMPTY = {
    pattern: /^(?!\s*$).+/,
    message: 'Обязательное для заполнения поле',
}
export const REQUIRED = {
    required: true,
    message: 'Обязательное для заполнения поле',
}

const MIN_PRICE = 0.01
const MAX_PRICE = 999_999_999_999.99

export const validatorConstructor = (
    condition: (() => boolean) | boolean,
    errorMessage: string,
) => {
    return {
        validator: async () => {
            if (typeof condition === 'boolean' ? condition : condition()) {
                return Promise.reject(new Error(errorMessage))
            }
            Promise.resolve()
        },
    }
}

export const dfaValidators = {
    ticker:
        (checkExistenceOfTicker?: (ticker: string) => Promise<IRequest<ResponseItem<boolean>>>) =>
        async (_: unknown, value: string) => {
            if (value && !/^[a-zA-Z0-9]+$/.test(value)) {
                return Promise.reject('Поле должно состоять из латинских букв и цифр')
            }
            if (value && (value.length < 3 || value.length > 6)) {
                return Promise.reject('Длина поля должна быть от 3 до 6 символов')
            }
            if (value && checkExistenceOfTicker) {
                try {
                    const { data } = await checkExistenceOfTicker(value)
                    if (data?.item) {
                        return Promise.reject('Такой тикер уже используется в системе')
                    }
                } catch (error) {
                    return Promise.reject('Не удалось проверить уникальность тикера')
                }
            }
            return Promise.resolve()
        },
    amount: (maxAmount?: number) => (_: unknown, value: string) => {
        const number = typeof value === 'number' ? value : stringToNumber(value)
        if (value && !isNaN(number) && number <= 0) {
            return Promise.reject('Значение поля быть должно больше нуля')
        }
        if (value && !isNaN(number) && maxAmount !== undefined && number > maxAmount) {
            return Promise.reject(`Значение поля не должно быть больше ${maxAmount}`)
        }
        return Promise.resolve()
    },
    price: (_: unknown, value: string) => {
        if (value && !/^(?!0\d)\d*((\.|,)\d{1,2})?$/.test(value)) {
            return Promise.reject('Введите корректное число')
        }
        const number = typeof value === 'number' ? value : Number(value?.replace(/,/g, '.'))
        if (number <= 0 && value !== undefined && value !== '') {
            return Promise.reject('Значение поля быть должно больше нуля')
        }
        if (number > MAX_PRICE && value) {
            return Promise.reject(
                `Значение поля должно быть не больше ${MAX_PRICE.toLocaleString()}`,
            )
        }
        return Promise.resolve()
    },
    biggerAmount:
        (notMore = 0) =>
        (_: unknown, value: string) => {
            const number = typeof value === 'number' ? value : stringToNumber(value)
            if (value && number <= 0) {
                return Promise.reject('Значение поля быть должно больше нуля')
            }
            if (!isNaN(number) && number > notMore) {
                return Promise.reject(`Значение поля не должно больше ${notMore}`)
            }
            return Promise.resolve()
        },

    spread: (_: unknown, value: string) => {
        const number = typeof value === 'number' ? value : parseInt(value, 10)
        if (value && isNaN(number)) {
            return Promise.reject('Значение поля быть должно цифрой')
        }

        return Promise.resolve()
    },
    issueStartDate: (_: unknown, value: Moment | string) => {
        if (value) {
            const valueMoment = moment.isMoment(value)
                ? value.startOf('minute')
                : moment(value, VIEW_DATE_FORMAT, true).startOf('minute')
            if (valueMoment.diff(moment().startOf('minute'), 'minute') <= 0) {
                return Promise.reject('Дата должна быть больше текущей')
            }
        }
        return Promise.resolve()
    },
    issueEndDate: (issueStartDate?: Moment | string) => (_: unknown, value: Moment | string) => {
        if (value && issueStartDate) {
            const valueMoment = moment.isMoment(value)
                ? value.startOf('minute')
                : moment(value, RUS_DATETIME_FORMAT, true).startOf('minute')
            const fromDateMoment = moment.isMoment(issueStartDate)
                ? issueStartDate.startOf('minute')
                : moment(issueStartDate, RUS_DATETIME_FORMAT, true).startOf('minute')
            if (valueMoment.diff(fromDateMoment, 'minutes') < 0) {
                return Promise.reject(
                    `Должно быть не ранее ${fromDateMoment.format(RUS_DATETIME_FORMAT)}`,
                )
            }
        }
        return Promise.resolve()
    },
    redeemDate: (issueEndDate?: Moment | string) => (_: unknown, value: Moment | string) => {
        if (value && issueEndDate) {
            const valueMoment = moment.isMoment(value)
                ? value.startOf('minute')
                : moment(value, RUS_DATETIME_FORMAT, true).startOf('minute')
            const fromDateMoment = moment.isMoment(issueEndDate)
                ? issueEndDate.startOf('minute')
                : moment(issueEndDate, RUS_DATETIME_FORMAT, true).startOf('minute')
            if (valueMoment.diff(fromDateMoment, 'minutes') <= 0) {
                return Promise.reject(
                    `Должно быть больше ${fromDateMoment.format(RUS_DATETIME_FORMAT)}`,
                )
            }
        }
        return Promise.resolve()
    },
  couponPaymentsStartDate: (
    issueStartDate?: Moment, 
    redeemDate?: Moment, 
    couponEndDate?: Moment) => (_: unknown, value: Moment) => {
      if (value) {
        const valueMoment = moment(value).startOf('day')
        if (!issueStartDate) {
          if (valueMoment.diff(moment().startOf('day'), 'days') < 0) {
            return Promise.reject(
              `Дата не может быть меньше текущей`,
            )
          }
          return Promise.resolve()
        }
        const fromDateMoment = issueStartDate.clone().startOf('day')
        if (valueMoment.diff(fromDateMoment, 'days') <= 0) {
          return Promise.reject(
            `Дата должна быть больше ${fromDateMoment.format(VIEW_DATE_FORMAT)}`,
          )
        }
        const endDate = couponEndDate || redeemDate
        if (endDate) {
          const endDateMoment = endDate.clone().startOf('day')
          if (valueMoment.diff(endDateMoment, 'days') >= 0) {
            return Promise.reject(
              `Дата должна быть меньше ${endDateMoment.format(VIEW_DATE_FORMAT)}`,
            )
          }
        }
      }
      return Promise.resolve()
    },
    couponPaymentsEndDate: (
      issueStartDate?: Moment,
      redeemDate?: Moment,
      couponStartDate?: Moment) => (_: unknown, value: Moment) => {
        if (value) {
          const valueMoment = moment(value).startOf('day')
          const fromDate = couponStartDate || issueStartDate
          if (!fromDate) {
            if (valueMoment.diff(moment().startOf('day'), 'days') < 0) {
              return Promise.reject(
                `Дата не может быть меньше текущей`,
              )
            }
            return Promise.resolve()
          }
          const fromDateMoment = fromDate.clone().startOf('day')
          if (valueMoment.diff(fromDateMoment, 'days') <= 0) {
            return Promise.reject(
              `Дата должна быть больше ${fromDateMoment.format(VIEW_DATE_FORMAT)}`,
            )
          }
          if (redeemDate) {
            const endDateMoment = redeemDate.clone().startOf('day')
            if (valueMoment.diff(endDateMoment, 'days') >= 0) {
              return Promise.reject(
                `Дата должна быть меньше ${endDateMoment.format(VIEW_DATE_FORMAT)}`,
              )
            }
          }
        }
        return Promise.resolve()
      },
    couponPaymentDate: (
      issueStartDate?: Moment,
      redeemDate?: Moment
    ) => (_: unknown, value: Moment) => {
      if (value) {
        const valueMoment = moment(value).startOf('day')
        if (!issueStartDate) {
          if (valueMoment.diff(moment().startOf('day'), 'days') < 0) {
            return Promise.reject(
              `Дата не может быть меньше текущей`,
            )
          }
          return Promise.resolve()
        }
        const fromDateMoment = issueStartDate.clone().startOf('day')
        if (valueMoment.diff(fromDateMoment, 'days') <= 0) {
          return Promise.reject(
            `Дата должна быть больше ${fromDateMoment.format(VIEW_DATE_FORMAT)}`,
          )
        }
        if (redeemDate) {
          const endDateMoment = redeemDate.clone().startOf('day')
          if (valueMoment.diff(endDateMoment, 'days') >= 0) {
            return Promise.reject(
              `Дата должна быть меньше ${endDateMoment.format(VIEW_DATE_FORMAT)}`,
            )
          }
        }
      }
      return Promise.resolve()
    },
    couponPaymentAmount: (_: unknown, value: string) => {
      const number = typeof value === 'number' ? value : Number(value?.replace(/,/g, '.'))
      if (number <= 0 && value !== undefined && value !== '') {
        return Promise.reject('Значение поля быть должно больше нуля')
      }
      if (number < MIN_PRICE && value) {
        return Promise.reject(
          `Значение поля должно быть не меньше ${MIN_PRICE.toLocaleString()}`,
        )
      }
      if (number > MAX_PRICE && value) {
        return Promise.reject(
          `Значение поля должно быть не больше ${MAX_PRICE.toLocaleString()}`,
        )
      }
      return Promise.resolve()
    },
}

export const commonValidators = {
    name:
        (maxLength = 100) =>
        (_: unknown, value: string) => {
            /*       if (value && !/^[А-ЯЁа-яё\s-]*$/.test(value)) {
                return Promise.reject('Поле содержит недопустимый символ')
            }*/
            if (value && !/^[А-ЯЁ]/.test(value)) {
                return Promise.reject('Поле должно начинаться с заглавной буквы')
            }
            if (value && value.length > maxLength) {
                return Promise.reject(`Длина поля должна быть не более ${maxLength} символов`)
            }
            return Promise.resolve()
        },
    number: (count?: number) => (_: unknown, value: string) => {
        if (value && !/^[0-9]+$/.test(value)) {
            return Promise.reject('Поле должно состоять из цифр')
        }
        if (value && count && value.length !== count) {
            return Promise.reject(`Длина поля должна быть равна ${count} символам`)
        }
        return Promise.resolve()
    },
    address:
        (maxLength = 100) =>
        (_: unknown, value: string) => {
            if (value && !/^[А-ЯЁа-яё0-9\s,/.'"-]*$/.test(value)) {
                return Promise.reject('Поле содержит недопустимый символ')
            }
            if (value && value.length > maxLength) {
                return Promise.reject(`Длина поля должна быть не более ${maxLength} символов`)
            }
            return Promise.resolve()
        },
    stringWithSpaces:
        (maxLength = 100) =>
        (_: unknown, value: string) => {
            if (value && !/^[А-ЯЁа-яё\s]+$/.test(value)) {
                return Promise.reject('Поле должно состоять из букв кириллицы и пробелов')
            }
            if (value && value.length > maxLength) {
                return Promise.reject(`Длина поля должна быть не более ${maxLength} символов`)
            }
            return Promise.resolve()
        },
    stringWithSpacesAndDash:
        (maxLength = 100) =>
        (_: unknown, value: string) => {
            if (value && !/^[А-ЯЁа-яё\s-.]+$/.test(value)) {
                return Promise.reject('Поле должно состоять из букв кириллицы, пробелов, тире')
            }
            if (value && value.length > maxLength) {
                return Promise.reject(`Длина поля должна быть не более ${maxLength} символов`)
            }
            return Promise.resolve()
        },
    positive: (maxValue?: number) => (_: unknown, value?: string | number) => {
        const number = typeof value === 'number' ? value : stringToNumber(value)
        if (value !== undefined && value !== '' && number <= 0) {
            return Promise.reject('Значение поля быть должно больше нуля')
        }
        if (value && maxValue !== undefined && number > maxValue) {
            return Promise.reject(`Значение поле не должно превышать ${maxValue}`)
        }
        return Promise.resolve()
    },
    minMax: (minValue: number, maxValue: number) => (_: unknown, value?: string) => {
        if (!value) {
            return Promise.resolve()
        }

        if (value.length <= minValue) {
            return Promise.reject(`Значение поля быть должно содрежать ${minValue} символов`)
        }
        if (value.length > maxValue) {
            return Promise.reject(`Значение поле не должно превышать ${maxValue} символов`)
        }
        return Promise.resolve()
    },
}

const checkControlDigitSnils = (value: string) => {
    if (value?.length !== 11) {
        return false
    }
    let sum = 0
    for (let i = 0; i < 9; i += 1) {
        sum += parseInt(value[i], 10) * (9 - i)
    }
    sum = sum > 101 ? sum % 101 : sum
    const controlDigit = sum < 100 ? sum : 0
    if (controlDigit === parseInt(value.slice(-2), 10)) {
        return true
    }
    return false
}
const checkDigitInnHelper = (inn: string, coefficients: number[]) => {
    const verify = coefficients.reduce((sum, coefficient, idx) => {
        sum += coefficient * parseInt(inn[idx], 10)
        return sum
    }, 0)
    return (verify % 11) % 10
}
const checkControlDigitInn = (value: string) => {
    switch (value?.length) {
        case 10:
            if (
                checkDigitInnHelper(value, [2, 4, 10, 3, 5, 9, 4, 6, 8]) === parseInt(value[9], 10)
            ) {
                return true
            }
            break
        case 12:
            if (
                checkDigitInnHelper(value, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]) ===
                    parseInt(value[10], 10) &&
                checkDigitInnHelper(value, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]) ===
                    parseInt(value[11], 10)
            ) {
                return true
            }
            break
        default:
            return false
    }
    return false
}

const checkControlDigitOgrn = (value: string) => {
    switch (value?.length) {
        case 13: {
            const controlDigit = parseInt(
                (parseInt(value.slice(0, -1), 10) % 11).toString().slice(-1),
                10,
            )
            if (controlDigit === parseInt(value[12], 10)) {
                return true
            }
            break
        }
        case 15: {
            const controlDigit = parseInt(
                (parseInt(value.slice(0, -1), 10) % 13).toString().slice(-1),
                10,
            )
            if (controlDigit === parseInt(value[14], 10)) {
                return true
            }
            break
        }
        default:
            return false
    }
    return false
}

export enum InnCheck {
    checkInnExist = 'checkInnExist',
    checkInnNotExist = 'checkInnNotExist',
    none = 'none',
}

export const documentValidators = {
    passportIssuerCode: (_: unknown, value: string) => {
        if (value && !/^[0-9]{3}-[0-9]{3}$/.test(value)) {
            return Promise.reject('Поле должно удовлетворять маске "___-___"')
        }
        return Promise.resolve()
    },
    passportIssuer: (_: unknown, value: string) => {
        if (value && !/^[А-ЯЁа-яё0-9\s/.№-]*$/.test(value)) {
            return Promise.reject('Поле содержит недопустимый символ')
        }
        if (value && value.length > 300) {
            return Promise.reject('Длина поля должна быть не более 300 символов')
        }
        return Promise.resolve()
    },
    snils: (_: unknown, value: string) => {
        if (value && !/^[0-9]{11}$/.test(value)) {
            return Promise.reject('СНИЛС должен состоять из 11 цифр')
        }
        if (value && !checkControlDigitSnils(value)) {
            return Promise.reject('Неверная контрольная сумма')
        }
        return Promise.resolve()
    },
    inn: (_: unknown, value: string) => {
        if (value && !/^[0-9]{10}$|^[0-9]{12}$/.test(value)) {
            return Promise.reject(`ИНН должен состоять из 10 или 12 цифр`)
        }
        if (value && !checkControlDigitInn(value)) {
            return Promise.reject('Неверная контрольная сумма')
        }
        return Promise.resolve()
    },
    innWithExistenceCheck:
        (
            count: 10 | 12,
            checkInnExistenceText: string,
            checkInnNotExistenceText: string,
            checkType: InnCheck,
            checkExistenceOfInn: (
                profile_type: ProfileType,
                inn: string,
            ) => Promise<IRequest<ResponseItem<boolean>>>,
            profile_type?: ProfileType,
            noAvailableInn?: string | number | undefined | null,
        ) =>
        async (_: unknown, value: string) => {
            const regExp = new RegExp('^[0-9]{' + count + '}$', 'gi')
            if (value && !regExp.test(value)) {
                return Promise.reject(`ИНН должен состоять из ${count} цифр`)
            }
            if (value && !checkControlDigitInn(value)) {
                return Promise.reject('Неверная контрольная сумма')
            }
            if (noAvailableInn && value === noAvailableInn) {
                return Promise.reject('ИНН не должен совпадать с Вашим ИНН')
            }
            if (profile_type && value && value.trim().length === count) {
                try {
                    const { data } = await checkExistenceOfInn(profile_type, value)
                    if (checkType === InnCheck.checkInnNotExist) {
                        if (data && data.item === true) {
                            return Promise.reject(checkInnNotExistenceText)
                        }
                    } else {
                        if (data && data.item === false) {
                            return Promise.reject(checkInnExistenceText)
                        }
                    }
                } catch (error) {
                    return Promise.reject('Невозможно отправить запрос для проверки ИНН')
                }
            }

            return Promise.resolve()
        },
    ogrn: (count: 13 | 15) => (_: unknown, value: string) => {
        const regExp = new RegExp('^[0-9]{' + count + '}$', 'gi')
        if (value && !regExp.test(value)) {
            return Promise.reject(
                `${count === 13 ? 'ОГРН' : 'ОГРНИП'} должен состоять из ${count} цифр`,
            )
        }
        if (value && !checkControlDigitOgrn(value)) {
            return Promise.reject('Неверная контрольная сумма')
        }
        return Promise.resolve()
    },
}

export const addressValidators = {
    house: (_: unknown, value: string) => {
        if (value && !/^[А-ЯЁа-яё0-9/]*$/.test(value)) {
            return Promise.reject('Поле содержит недопустимый символ')
        }
        if (value && value.length > 10) {
            return Promise.reject('Длина поля должна быть не более 10 символов')
        }
        return Promise.resolve()
    },
}

export const contactValidators = {
    email: (_: unknown, value: string) => {
        const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (value && !regex.test(value)) {
            return Promise.reject('Поле должно удовлетворять шаблону "___@___.__"')
        }
        if (value && value.length > 254) {
            return Promise.reject('Длина поля должна быть не более 254 символов')
        }
        return Promise.resolve()
    },
    webSite: (_: unknown, value: string) => {
        const regex =
            /^(http|https):\/\/[a-zа-я0-9]+([-.]{1}[a-zа-я0-9]+)*\.[a-zа-я]{2,5}(:[0-9]{1,5})?(\/.*)?/
        const _value = value?.toLowerCase()
        if (value && !regex.test(_value)) {
            return Promise.reject('Поле должно удовлетворять шаблону "http(s)://___.__"')
        }
        if (value && _value.length > 100) {
            return Promise.reject('Длина поля должна быть не более 100 символов')
        }
        return Promise.resolve()
    },
    phone:
        (count = 10) =>
        (_: unknown, value: string) => {
            if (value && (!/^[0-9]+$/.test(value) || value.length !== count)) {
                return Promise.reject('Введите корректный номер телефона')
            }
            return Promise.resolve()
        },
}

export const moneyValidators = {
    maxSum: (max: number, errorMessage?: string) => (_: unknown, value: string) => {
        const number = typeof value === 'number' ? value : stringToNumber(value)
        if (value && number <= 0) {
            return Promise.reject('Значение поля должно быть больше нуля')
        }
        if (number > max && value) {
            return Promise.reject(
                errorMessage ?? `Значение поля должно быть не больше ${max.toLocaleString()}`,
            )
        }
        return Promise.resolve()
    },
    debitSum: ({ max , min} : {max: number, min: number}) => (_: unknown, value: string) => {
      const number = typeof value === 'number' ? value : stringToNumber(value)
      if (value && number <= 0) {
        return Promise.reject('Значение поля должно быть больше нуля')
      }
      if (number > max && value) {
        return Promise.reject(
          `Значение поля должно быть не больше ${max.toLocaleString()}`,
        )
      }
      if (number <= min && value) {
        return Promise.reject(
          `Значение поля должно быть больше ${min.toLocaleString()}`,
        )
      }
      return Promise.resolve()
    },
}
type DfaMaxSumType = {
    pricePerDFA: number
    maxCount: number
    maxMoney?: number
    maxLimit?: number
}
type DfaMaxDynamicSumType = {
    maxMoney?: number
    maxLimit?: number
}

export const dfaTransferValidators = {
    maxSum:
        ({ pricePerDFA, maxMoney, maxCount, maxLimit }: DfaMaxSumType) =>
        (_: unknown, value: string) => {
            const number =
                typeof value === 'number'
                    ? value
                    : Number(value?.replace(/,/g, '.').replace(/ /g, ''))

            if (number > maxCount && value) {
                return Promise.reject(`Доступно ${maxCount.toLocaleString()}`)
            }
            if (number <= 0 && value) {
                return Promise.reject('Значение поля должно быть больше нуля')
            }
            if (typeof maxLimit === 'number' && number * pricePerDFA > maxLimit) {
                return Promise.reject('Превышен лимит для неквалифицированного инвестора')
            }
            if (typeof maxMoney === 'number' && number * pricePerDFA > maxMoney) {
                return Promise.reject('Недостаточно средств для покупки указанного количества ЦФА')
            }
            return Promise.resolve()
        },
    maxDynamicSum:
        ({ maxMoney, maxLimit }: DfaMaxDynamicSumType) =>
        (_: unknown, value: string) => {
            const number =
                typeof value === 'number'
                    ? value
                    : Number(value?.replace(/,/g, '.').replace(/ /g, ''))
            if (value && number <= 0) {
                return Promise.reject('Значение поля должно быть больше нуля')
            }
            if (typeof maxLimit === 'number' && number > maxLimit) {
                return Promise.reject('Превышен лимит для неквалифицированного инвестора')
            }
            if (typeof maxMoney === 'number' && number > maxMoney) {
                return Promise.reject('Недостаточно средств для покупки указанного количества ЦФА')
            }
            return Promise.resolve()
        },
}

export const dateValidators = {
    birthdate: (_: unknown, value: Moment | string) => {
        const valueMoment = moment.isMoment(value) ? value : moment(value, VIEW_DATE_FORMAT, true)
        const age = moment().add(-1, 'day').diff(valueMoment, 'years')
        if (value && (age < 18 || age > 130)) {
            return Promise.reject(
                'Дата рождения должна быть не менее 18 полных лет от текущей даты и не более 130 лет',
            )
        }
        return Promise.resolve()
    },
    onlyFuture: (_: unknown, value: Moment | string) => {
        if (value) {
            const valueMoment = moment.isMoment(value)
                ? moment(value).startOf('day')
                : moment(value, VIEW_DATE_FORMAT, true).startOf('day')
            if (valueMoment.diff(moment().startOf('day'), 'days') <= 0) {
                return Promise.reject('Дата не может быть меньше текущей')
            }
        }
        return Promise.resolve()
    },
    isValid: (_: unknown, value: Moment | string) => {
        if (value && !(moment.isMoment(value) || moment(value, VIEW_DATE_FORMAT, true).isValid())) {
            return Promise.reject('Некорректная дата')
        }
        return Promise.resolve()
    },
    moreDate: (fromDate?: Moment | string) => (_: unknown, value: Moment | string) => {
        if (value && fromDate) {
            const valueMoment = moment.isMoment(value)
                ? moment(value).startOf('day')
                : moment(value, VIEW_DATE_FORMAT, true).startOf('day')
            const fromDateMoment = moment.isMoment(fromDate)
                ? fromDate.clone().startOf('day')
                : moment(fromDate, VIEW_DATE_FORMAT, true).startOf('day')
            if (valueMoment.diff(fromDateMoment, 'days') < 0) {
                return Promise.reject(
                    `Дата не может быть меньше ${fromDateMoment.format(VIEW_DATE_FORMAT)}`,
                )
            }
        }
        return Promise.resolve()
    },
    lessDate: (toDate?: Moment | string) => (_: unknown, value: Moment | string) => {
        if (value && toDate) {
            const valueMoment = moment.isMoment(value)
                ? moment(value).startOf('day')
                : moment(value, VIEW_DATE_FORMAT, true).startOf('day')
            const toDateMoment = moment.isMoment(toDate)
                ? toDate.clone().startOf('day')
                : moment(toDate, VIEW_DATE_FORMAT, true).startOf('day')
            if (toDateMoment.diff(valueMoment, 'days') < 0) {
                return Promise.reject(
                    `Дата не может быть позже ${toDateMoment.format(VIEW_DATE_FORMAT)}`,
                )
            }
        }
        return Promise.resolve()
    },
}

export const fileValidators = {
    maxSize: (file: RcFile, maxSizeMb?: number) => {
        return !maxSizeMb || file.size / 1024 / 1024 <= maxSizeMb
    },
}

export const authValidators = {
    login: (_: unknown, value: string) => {
        if (value && !/^[a-zA-Z0-9\-_.]+$/.test(value)) {
            return Promise.reject('Должен состоять из латинских букв, цифр и символов . - _')
        }
        return Promise.resolve()
    },
    setLogin: (_: unknown, value: string) => {
        if (value && (value.length < 6 || value.length > MAX_LOGIN_LENGTH)) {
            return Promise.reject(`Длина поля должна быть от 6 до ${MAX_LOGIN_LENGTH} символов`)
        }
        if (value && !/^[a-zA-Z0-9\-_.]+$/.test(value)) {
            return Promise.reject('Должен состоять из латинских букв, цифр и символов . - _')
        }
        return Promise.resolve()
    },
    setPassword: (_: unknown, value: string) => {
        if (value && value.length < 10) {
            return Promise.reject('Минимальная длина пароля - 10 символов')
        }
        if (value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])/.test(value)) {
            return Promise.reject(
                'Пароль должен обязательно содержать цифры, строчные, прописные буквы и спец. символы',
            )
        }
        return Promise.resolve()
    },
    comparePassword: (originalPassword: string) => (_: unknown, value: string) => {
        if (value && value !== originalPassword) {
            return Promise.reject('Пароли должны совпадать')
        }
        return Promise.resolve()
    },
    loginOrEmail: (_: unknown, value: string) => {
        if (value && /^.*@.*$/.test(value)) {
            const regex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (value && !regex.test(value)) {
                return Promise.reject('Поле должно удовлетворять шаблону "___@___.__"')
            }
            if (value && value.length > 254) {
                return Promise.reject('Длина поля должна быть не более 254 символов')
            }
        } else if (value && !/^[a-zA-Z0-9\-_.]+$/.test(value)) {
            return Promise.reject('Должен состоять из латинских букв, цифр и символов . - _')
        }
        return Promise.resolve()
    },
}

export const areAllChecksValid = (allFields: FieldData[]) =>
    !allFields.find(
        (field) => !field.touched || (Array.isArray(field.errors) && field.errors.length > 0),
    )

type BankAccountValidatorType = {
    checkExistenceOfAccount?: (
        data: CheckUniqueBankAccountRequest,
    ) => Promise<IRequest<CheckUniqueBankAccountResponse>>
    bic?: string
}
export const bankValidators = {
    accountNumber:
        ({ checkExistenceOfAccount, bic }: BankAccountValidatorType) =>
        async (_: unknown, value: string) => {
            if (value && !/^[0-9]+$/.test(value)) {
                return Promise.reject('Поле должно состоять из цифр')
            }
            if (value && value.length !== 20) {
                return Promise.reject('Длина поля должна быть равна 20 символам')
            }
            if (value && checkExistenceOfAccount && bic && /^[0-9]{9}$/.test(bic)) {
                try {
                    const { data } = await checkExistenceOfAccount({
                        account_number: value,
                        bic,
                    })
                    if (data?.is_exist) {
                        return Promise.reject('Банковский счёт не уникален')
                    }
                } catch (error) {
                    return Promise.reject('Не удалось проверить уникальность счёта')
                }
            }
            return Promise.resolve()
        },
}
