const DEFAULT_ACTIVITY_EVENTS = [
    'click',
    'mousemove',
    'keydown',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'focus',
] as string[]

const ACTIVITY_TIMEOUT = 60 * 1000 * 10
const RECALL_TIMEOUT = 15000

const addEventListeners = (activityEvents: string[], handleActivityEvent: () => void) => {
    activityEvents.forEach((eventName) => window.addEventListener(eventName, handleActivityEvent))
}

const removeEventListeners = (activityEvents: string[], handleActivityEvent: () => void) => {
    activityEvents.forEach((eventName) =>
        window.removeEventListener(eventName, handleActivityEvent),
    )
}

export const timerCallback = () => {
    let isPaused = false
    let startArgs: { cb: () => void; timeout: number } | null = null
    let timer: ReturnType<typeof setTimeout> | null = null
    let activityTimer: ReturnType<typeof setTimeout> | null = null

    const activityTimoutHandler = () => {
        isPaused = true
        clear()
    }

    const activityHandler = () => {
        activityTimer && clearTimeout(activityTimer)
        activityTimer = null
        activityTimer = setTimeout(activityTimoutHandler, ACTIVITY_TIMEOUT)

        if (isPaused && startArgs) {
            isPaused = false
            startPending(startArgs?.cb, startArgs?.timeout)
        }
    }

    const startPending = (cb: () => void, timeout: number = RECALL_TIMEOUT) => {
        cb()
        startArgs = { cb, timeout }
        addEventListeners(DEFAULT_ACTIVITY_EVENTS, activityHandler)
        if (!activityTimer) {
            activityTimer = setTimeout(activityTimoutHandler, ACTIVITY_TIMEOUT)
        }

        timer = setTimeout(function myTimer() {
            cb()
            timer = setTimeout(myTimer, timeout)
        }, timeout)
    }

    const clear = () => {
        timer && clearTimeout(timer)
        timer = null
    }

    const stopPending = () => {
        clear()
        activityTimer && clearTimeout(activityTimer)
        activityTimer = null
        removeEventListeners(DEFAULT_ACTIVITY_EVENTS, activityHandler)
    }

    return { startPending, stopPending }
}
